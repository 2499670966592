import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import Five from '../components/Five'

const LeftSidebar = props => (
  <Layout>
    <Helmet>
      <title>Right Sidebar - Landed by HTML5 UP</title>
      <meta name="description" content="Right Sidebar Page" />
    </Helmet>
    <Five />
    
  </Layout>
)

export default LeftSidebar
